/* navbar.css */
.navBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(211, 211, 211, 0.75);
  padding: 10px 20px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.navLinks {
  display: flex;
  gap: 15px; /* リンク間の隙間 */
}

.navLinks a {
  color: white;
  text-decoration: none;
  font-weight: bold; /* テキストを太くする */
}

.socialIcons {
  display: flex;
  gap: 10px;
  align-items: center;
  color: white;
}

.menuButton {
  display: none;
  color: white;
  background-color: transparent; /* ボタンの背景を透明に */
  border: none; /* ボタンの枠線を削除 */
  font-size: 24px; /* アイコンのサイズを調整 */
}

@media (max-width: 768px) {
  .menuButton {
    display: block;
  }

  .navLinks {
    position: absolute; /* ドロップダウンメニューの位置を調整 */
    top: 60px; /* ナビゲーションバーからのオフセット */
    right: 10px; /* 右端からのオフセット */
    flex-direction: column;
    gap: 10px; /* リンク間の隙間を設定 */
    background-color: #333; /* メニューの背景色 */
    padding: 10px;
    border-radius: 5px; /* 角丸の設定 */
    display: none; /* 初期状態では非表示 */
  }

  .navLinks.open {
    display: flex; /* メニューが開かれたときに表示 */
  }
}
